<template>
  <div id="gcash-error-page">
    <div class="gcash-error-container">
      <div>
        <img
          :src="require('@/assets/gcash_logo.webp')"
          alt="gcash logo"
          width="300"
          class="is-align-self-center gcash-error-logo-mobile"
        />
      </div>
      <div class="gcash-error-content">
        <div class="gcash-error-logo">
          <img
            :src="require('@/assets/cat_logo.webp')"
            alt="coopnet logo"
            width="200"
            class="is-align-self-center gcash-error-cat-logo-mobile"
          />
        </div>

        <div class="gcash-error-details">
          <p class="gcash-error-header">Sorry about that</p>
          <p class="gcash-error-description">
            It took you too long to confirm the transaction. Please try again
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GCashErrorPageComponent",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#gcash-error-page {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 87, 228, 1) 40%,
    rgba(255, 255, 255, 1) 40%
  );
  height: 100vh;
  width: 100vw;
}

.gcash-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100%;
  width: 100%;
}

.gcash-error-content {
  margin: 1rem;
  padding: 3rem 1rem;
  border-radius: 12px;
  background: #fff;
  -webkit-box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 8px 25px -8px rgba(0, 0, 0, 0.4);
}

.gcash-error-header {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 1rem auto;
}

.gcash-error-description {
  margin: 0 auto;
  width: 80%;
}

@media screen and (max-width: 425px) {
  .gcash-error-logo-mobile {
    width: 250px;
  }

  .gcash-error-cat-logo-mobile {
    width: 150px;
  }
}
</style>
